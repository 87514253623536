import React from "react";
interface IInput {
    text: string,
    className?: string;
    iType?: string,
}
const InputTextTemplate = ({ id, description, className, input, meta, text, iType, }: IInput & any): any => {
    // console.log(input)
    const inpClass = (className !== undefined ? className + "" : 'form-control')
    const t: string = iType === undefined ? 'text' : iType;
    return <div className="row pb-2 pt-2">
        <div className="col-sm-12 col-md-2">
            <label>{text}</label>
            <p className="descriptionText">{description}</p>
        </div>
        <div className="col-sm-12 col-md-8">
            <input id={id} type={t} className={inpClass} {...input} placeholder="...." />
        </div>
        <div className="col-sm-12 col-md-2">
            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
        </div>

    </div>
}

export default InputTextTemplate;