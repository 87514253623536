import React, { useEffect, useRef, useState } from 'react';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import { RequestDeleteImage, RequestGetImages } from '../../../api/image-requests';
import { RequestNewLog } from '../../../api/log-requests';
import DataTable from '../../../components/system/DataTable';
import DebugButton from '../../../components/system/DebugButton';
import { ENotificationType, useNotifications } from '../../../context/notifications';
import { IImage } from '../../../interface/image';
import { ELogType, ESeverity } from '../../../interface/log';
import AdminImage from './Admin.Image';


const AdminImagesPge = ({ }: any) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [things, setThings] = useState<IImage[]>();
    let match = useRouteMatch();
    const { addNotification } = useNotifications()!;



    {/*<!-- OPTIONAL 1 -->*/ }


    {/*<!-- OPTIONAL END -->*/ }



   
    const mounted = useRef(false);
    useEffect(() => {
        try {
            if (mounted.current === false) {
                mounted.current = true

                {/*<!-- OPTIONAL 1 -->*/ }
                RequestGetImages(addNotification).then(thing => {
                    //   console.log(stores)
                    setThings(thing);
                    setLoading(false);
                })

                {/*<!-- OPTIONAL END -->*/ }
            }
        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Images - useEffect', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }

    }, [loading]);

    const onDelete = (value: IImage) => {
        try {
            const conf = window.confirm("Delete?");
            if (conf) {
                RequestDeleteImage(addNotification, value).then(res => {
                    //  console.log(res);
                    setThings([]);
                    setLoading(true);
                    addNotification && addNotification('Success', 'Image Deleted!', ENotificationType.Primary);
                });
            }
        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Images - onDelete', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }
    }



    return (<>

        {things && <div className="col-12">
            <h2>Images Admin</h2>
            {/*<!-- OPTIONAL 2 -->*/}
            <DataTable

                colHeadings={[
                    { name: "Name", field: "name", sortable: false },
                    {
                        name: "Image", field: "id", r: (value: any, index) => {
                            return <>{value.url && <img className="img-fluid" src={value.url} />}</>;
                        }, sortable: false
                    },
                    {
                        name: "Actions", field: "id", r: (value: any, index) => {
                            return <div className="btn-group">
                                <DebugButton data={value} />
                                <Link className="btn btn-primary" to={`${match.url}/${value.id}`}>Edit</Link>
                                <button className="btn btn-danger" onClick={(ev: React.MouseEvent) => {
                                    ev.preventDefault();
                                    onDelete(value);
                                }}>Delete</button>
                            </div>;
                        }, sortable: false
                    },

                ]}

                data={things}
            />

            {/*<!-- OPTIONAL END -->*/}
        </div>}
    </>
    );
};
const AdminImages = ({ }: any) => {
    let match = useRouteMatch();
    return (<>
        <Switch>
            <Route path={`${match.path}/:id`}>
                <AdminImage />
            </Route>
            <Route path={`${match.path}`} exact>
                <AdminImagesPge />
            </Route>

        </Switch>
    </>
    );
}

export default AdminImages;