import { faAngleDoubleUp, faArrowDown, faArrowUp, faBars, faCheckCircle, faDownload, faPrint, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as _ from "lodash";
import React, { RefObject, useEffect, useRef, useState } from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { CSVLink } from "react-csv";

import { useReactToPrint } from 'react-to-print';
interface IExportField {
  name: string;
  field: string;
}

interface IDDoWithSelected {
  title: string;
  name: string;
  func?: (things: any[]) => void;
}


const isOdd = (num: number) => { return num % 2; }

const DataTable = <T,>(props: {
  defaultSort?: string;
  data: any[];
  enablePrint?: boolean;
  searchable?: boolean;
  rowClass?: string;
  colHeadings?: {
    name: string;
    field: string;
    sortable: boolean;
    width?: string;
    align?: 'left' | 'center' | 'right'
    isButtonField?: boolean;
    queryable?: boolean;
    rsx?: (value: T, index: number) => string; // render spereadsheet export 
    rpx?: (value: T, index: number) => string; // render print export
    r?: (value: T, index: number, addToQuery?: (field: string, d: any) => void) => React.ReactNode; // render table 
  }[];
  addColHeadings?: IExportField[];
  exportFields?: IExportField[];
  hideIndexCol?: boolean;
  query?: any;
  topRef?: RefObject<any>;
  topText?: string;
  exportDataContext?: string;
  exportDataId?: any;
  exportFileName?: string;
  onQueryUpdate?: (results: any[]) => void;
  onDelete?: (thing: any) => void;
  Children?: React.ReactNode;
  rowStyleFn?: (value: any) => any;
  rowExpanded?: (value: any, index: number, addToQuery?: (d: any) => void) => React.ReactNode;

  doWithSelected?: {
    title: string;
    name: string;
    func?: (things: T[]) => void
  }[];
}) => {


  const topOfTable = useRef<any>()
  const [query, setQuery] = useState<any>({});
  //const [mode, setMode] = useState<string>('table');

  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  //paginate stuff
  const [expandedIndex, setExpandedIndex] = useState<number>(-1);
  const [sortedCount, setSortedCount] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(15);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [currentData, setCurrentData] = useState<any[]>([]);
  const [sortedArrayStore, setSortedArrayStore] = useState<any[]>([]);
  const [pages, setPages] = useState<number>(1);
  const [selected, setSelected] = useState<any[]>([]);
  const sortString = (props.defaultSort ? props.defaultSort : '');
  const [sortBy, setSortBy] = useState<string>(sortString);
  const [sortByReverse, setSortByReverse] = useState<boolean>(true);


  const clearQuery = () => {
    setQuery({});
  }

  const removeFromQuery = (fieldName: any) => {
    let q = { ...query };
    delete q[fieldName];
    setQuery(q);
  }

  const addToQuery = (fieldName: any, value: any) => {
    console.log("addToQuery", fieldName, value)
    let q = { ...query };
    q[fieldName] = value;
    setQuery(q);

    const offset = 0 * perPage;
    setOffset(offset)
    setCurrentPage(0)
    //  topOfTable.current.scrollIntoView()
  }

  useEffect(() => {
    // console.log("useEffect")
    if (props.data) {

      const items = _.cloneDeep(props.data)
        .map((payload: any, pIndex: number) => {
          props && props.colHeadings &&
            props.colHeadings.map((head, index) => {
              if (head.rsx) {
                const fName = head.field + '_rx';
                payload[fName] = head.rsx(payload, index);
              }

              return head
            })
          return payload;
        });

      //RX injection?


      let cc = 1;
      //
      const indexAdded = items.map(ddd => {
        if (typeof ddd === 'object') {
          ddd.____index = cc;
        }
        cc++;
        return ddd;
      })
      let sortedArray: any[] = indexAdded;


      let actualQuery: any;
      if (props.query === undefined || props.query === null) {
        actualQuery = query
      } else {
        actualQuery = { ...props.query, ...query }
      }
      if (Object.keys(actualQuery).length > 0) {

        sortedArray = sortedArray.filter(value => {
          const keys = Object.keys(actualQuery)
          const sddd: boolean[] = [];
          for (let index = 0; index < keys.length; index++) {
            let valueSomething: boolean = false;
            const attributeName = keys[index];
            const attributeValue = actualQuery[attributeName];

            const valueValue = value[attributeName];

            if (Array.isArray(attributeValue)) {
              // there is a array of "query" values
              let wtf = attributeValue.map((dd: any, indexxd: number) => {
                // console.log(dd);

                if (Array.isArray(valueValue)) {
                  //value is a array

                  if (valueValue.includes(dd)) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  if (valueValue === dd) {
                    return true;
                  } else {
                    return false;
                  }

                }

              })
              //console.log(wtf)
              if (wtf.length === wtf.filter(sss => sss === true).length) {
                sddd.push(true);
              }
            } else {
              // one qury value
              if (Array.isArray(valueValue)) {
                //value is a array
                if (valueValue.includes(attributeValue)) {
                  valueSomething = true;
                } else {
                  valueSomething = false;
                }
              } else {
                if (valueValue === attributeValue) {
                  valueSomething = true;
                } else {
                  valueSomething = false;
                }

              }
              sddd.push(valueSomething);
            }



          }

          if (keys.length === sddd.filter(sss => sss === true).length) {
            return value;
          } else {
            return undefined
          }

        })
      }
      if (sortBy !== '') { // sort by 
        sortedArray.sort((obj1: any, obj2: any) => {
          if (sortByReverse === false) {
            return (obj1[sortBy] < obj2[sortBy] ? -1 : 1);
          } else {
            return (obj1[sortBy] > obj2[sortBy] ? -1 : 1)
          }
        });
      }

      setSortedCount(sortedArray.length);
      props.onQueryUpdate && props.onQueryUpdate(sortedArray);
      setSortedArrayStore(sortedArray)
      setPages(Math.ceil(sortedArray.length / perPage))
      setCurrentData(sortedArray.slice(offset, offset + perPage))
    }
  }, [props, offset, sortBy, sortByReverse, query, perPage])

  const onChooseSortBy = (field: string) => {
    //console.log("onChooseSortBy")
    if (sortBy === field) {
      setSortByReverse(!sortByReverse);
    } else {
      setSortBy(field);
      setSortByReverse(true);
    }


  }



  const onChoosePageClick = (pageNumber: number) => {
    //  console.log("choosePageClick")
    const offset = pageNumber * perPage;
    setOffset(offset)
    setCurrentPage(pageNumber)
    //  topOfTable.current.scrollIntoView()
  }




  //#endregion
  //console.log(props)

  let showIndexCol = true;
  if (props.hideIndexCol === true) {
    showIndexCol = false;
  }

  let pgSliceStart: number = 0;
  if (currentPage > 2) {
    pgSliceStart = (currentPage - 2);
  }
  let colCount = 2;

  if (props.colHeadings) {
    colCount = props.colHeadings.length;
  }

  if (showIndexCol) {
    colCount++;
  }

  if (props.doWithSelected) {
    colCount++;
  }


  const alignCSS = (thing: any) => {
    if (!thing.align) {
      return 'text-center';
    } else {
      switch (thing.align) {
        case 'right':
          return 'text-right';
        case 'left':
          return 'text-center';
      }
    }
  }

  const keys = Object.keys(query)



  const onChangePerPage = (perPage: number) => {
    setPages(Math.ceil(sortedArrayStore.length / perPage))
    setPerPage(perPage);


    onChoosePageClick(0);
  }



  // const exportCSVColHeadings = (): LabelKeyObject[] => {

  //   let ret: LabelKeyObject[] = [];
  //   if (props.exportFields) {
  //     ret = props.exportFields.map(field => {
  //       let header = { label: field.name, key: field.field }
  //       return header
  //     })
  //   }
  //   else if (props.colHeadings) {
  //     ret = props.colHeadings.filter(d => d.isButtonField !== true).map((vv, dd) => {
  //       let header = { label: vv.name, key: vv.field }
  //       if (vv.rsx !== undefined) {
  //         header.key = vv.field + '_rx';
  //       }
  //       return header
  //     })
  //   } else {
  //     ret = [];
  //   }



  //   if (props.addColHeadings) {
  //     ret = [...ret, ...props.addColHeadings.map(field => {
  //       let header = { label: field.name, key: field.field }
  //       return header
  //     })]
  //   }

  //   return ret;



  // }

  useEffect(() => {
    //console.log("ARRR", currentPage)
    onChoosePageClick(0);

  }, [props.data]);
  return (<>

    <div ref={topOfTable} className="btn-toolbar justify-content-between" role="toolbar" aria-label="Toolbar with button groups">
      <div className="btn-group" role="group" aria-label="First group">
        {keys.length > 0 && <>
          {keys.map((val: any, kindex) => (<button key={`kes${kindex}`} type="button" className="btn btn-outline-secondary" onClick={(ev: React.MouseEvent) => {
            ev.preventDefault();
            removeFromQuery(val)
          }}>{val}={query[val]}</button>))}
          <button type="button" className="btn btn-outline-secondary" onClick={(ev: React.MouseEvent) => {
            ev.preventDefault();
            clearQuery();
          }}>Clear</button>
        </>}

      </div>

      <div>
        {props.searchable && props.searchable === true && <div className="input-group">
          <div className="input-group-text" id="btnGroupAddon2">Search [not working]</div>
          <input type="text" className="form-control" placeholder="Input group example" aria-label="Input group example" aria-describedby="btnGroupAddon2" />
        </div>}
      </div>

    </div>
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            {props.doWithSelected && <th scope="col" style={{ "width": "10%" }}></th>}


            {showIndexCol && <th scope="col" style={{ "width": "10%" }}>
              {props.topRef !== undefined && <>

                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id={`tooltip`}>
                      {props.topText ? props.topText : "Top Of Page"}</Tooltip>
                  }>
                  <button className="btn btn-link" onClick={(ev: React.MouseEvent) => {
                    ev.preventDefault();
                    props.topRef && props.topRef.current.scrollIntoView()
                  }}><FontAwesomeIcon icon={faAngleDoubleUp} /></button>
                </OverlayTrigger>



              </>}
            </th>}
            {props.colHeadings && props.colHeadings.map((heading, index) => {
              let style: React.CSSProperties = {};
              if (heading.width) {
                style.width = heading.width;
              }
              if (heading.sortable === true) {
                return <th key={`head${index}`} style={style} scope="col" className={`
                ${alignCSS(heading)}
                `}><button data-testid={`heading_${index}_sortButton`} type="button" onClick={(ev: React.MouseEvent) => {
                    onChooseSortBy(heading.field)
                  }} className={`btn btn-link bold underline`}>{heading.name} {sortBy === heading.field && <>{sortByReverse === true ? <FontAwesomeIcon icon={faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} />}</>}</button></th>
              } else {
                return <th key={`heaed${index}`} style={style} scope="col" className={`${alignCSS(heading)}`}>{heading.name}</th>
              }

            })}

          </tr>
        </thead>

        <tbody>
          {currentData && currentData.map((thing: any, dataIndex: number) => {
            let rowClass = `e2eTableRow`;

            if (props.rowClass) {
              rowClass += ` ${props.rowClass} `;
            }
            if (isOdd(dataIndex)) {
              rowClass += ' tableOdd ';
            } else {
              rowClass += ' tableEven ';
            }
            // if (props.rowExpanded !== undefined && expandedIndex !== thing.____index) {
            //   rowClass += ' dataTableExpandable';
            // }
            //selected stuff!?
            let isSelected: boolean = false;
            if (selected.length > 0) {

              for (let index = 0; index < selected.length; index++) {
                const element = selected[index];
                if (element.____index === dataIndex + 1) {
                  isSelected = true;
                }
              }
              // console.log(selected, isSelected, dataIndex, thing._id, selected.indexOf((dd: any) => dd._id === thing._id))
            }



            const TR = (): JSX.Element => {
              return <><tr data-testid={`data_table_row_${thing.____index}`} key={`${dataIndex}+++`} className={rowClass} style={props.rowStyleFn && props.rowStyleFn(thing)}>



                {props.doWithSelected && <td><button type="button" onClick={(ev: React.MouseEvent) => {
                  ev.stopPropagation();
                  if (isSelected === true) {
                    const sel = [...selected];
                    const index = sel.findIndex(ddd => ddd.____index === (dataIndex + 1));
                    sel.splice(index, 1);
                    //   console.log(index);
                    // sel.push(thing);
                    setSelected(sel);
                  } else {
                    const sel = [...selected];
                    sel.push(thing);
                    setSelected(sel);
                  }
                }} className={`btn ${isSelected === false ? 'btn-outline-primary' : 'btn-outline-danger'}`}>{isSelected === false ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faTimesCircle} />}</button></td>}






                {showIndexCol && <th scope="row">{thing.____index}<br />
                  {props.rowExpanded &&

                    <OverlayTrigger

                      placement="right"
                      overlay={<Tooltip id={`tooltip`}>Toggle Expanded</Tooltip>}>
                      <button className="btn btn-outline-primary" onClick={(ev: React.MouseEvent) => {
                        ev.preventDefault();
                        if (props.rowExpanded) {
                          if (expandedIndex === thing.____index) {
                            setExpandedIndex(-1);
                          } else {
                            setExpandedIndex(thing.____index);
                          }
                        }
                      }}><FontAwesomeIcon icon={faBars} /></button>
                    </OverlayTrigger>}
                </th>}
                {props.colHeadings && props.colHeadings.map((heading, headingIndex) => {
                  let queryableField: boolean = false;
                  if (heading.queryable !== undefined) {
                    if (heading.queryable === true) {
                      queryableField = true;
                    }
                  }


                  const render = () => {
                    if (heading.r !== undefined) {
                      return <>{heading.r(thing, thing.____index, addToQuery)}</>;
                    } else {
                      if (queryableField === true) {
                        return <>
                          <button className="btn btn-link" type="button" onClick={(ev: React.MouseEvent) => {
                            ev.preventDefault();
                            addToQuery(heading.field, thing[heading.field]);
                          }}>{thing[heading.field]}</button>
                        </>;
                      } else {
                        return <>{thing[heading.field]}</>;
                      }
                    }
                  };


                  return <td key={`${headingIndex}+heading${dataIndex}`} data-testid={`Col_${dataIndex}_H_${headingIndex}`} className={`${alignCSS(heading)} e2e_${dataIndex}_${heading.field} ${props.rowClass !== undefined && `e2e2_${dataIndex}_${heading.field}_${props.rowClass}`} `}>
                    {render()}
                  </td>;
                })}

              </tr>
                {props.rowExpanded !== undefined && expandedIndex === thing.____index && <tr className={rowClass} key={dataIndex + "ddd_ss"}>
                  <td colSpan={colCount}>
                    <div className="row">
                      {props.rowExpanded(thing, thing.____index)}
                      <div className="col-12">


                        <button type="button" className="btn btn-warning" onClick={(ev: React.MouseEvent) => {
                          ev.preventDefault();
                          setExpandedIndex(-1);
                        }}>Close</button>

                      </div>
                    </div>
                  </td>
                </tr>}
              </>;
            }


            return <TR key={dataIndex} />
          })}

        </tbody>
      </table>
    </div>
    <div className="hiddenTable">

      <div ref={componentRef}>
        {props.enablePrint === true && <table data-testid="pdfExportTable" className="printTable">
          <thead>
            <tr>
              {props.colHeadings && props.colHeadings.filter(ddd => ddd.isButtonField !== true).map((vv, dd) => {
                return <th key={"d" + dd} data-testid={`pdfExportTable_H_${dd}`}>{vv.name}</th>
              })}
              {props.addColHeadings && props.addColHeadings.map((vv, dd) => {
                return <th key={"cdfsdffd" + dd} data-testid={`pdfExportTable_H2_${dd}`}>{vv.name}</th>
              })}
            </tr>
          </thead>
          <tbody>
            {sortedArrayStore.map((data: any, dindex: number) => {
              return <tr key={`trrrrr${dindex}`} data-testid={`pdfExportTable_row_${dindex}`}>
                {props.colHeadings && props.colHeadings.filter(ddd => ddd.isButtonField !== true).map((vv, ddIndex) => {
                  return <td key={`${dindex}sdffss${ddIndex}`} data-testid={`pdfExportTable_R_${dindex}_D_${ddIndex}`}>
                    {vv.rpx !== undefined ? <>{vv.rpx(data, ddIndex)}</> : <>
                      {Array.isArray(data[vv.field]) === true ? <>{data[vv.field].map((dd: any, iiindex: number) => {
                        return <p key={`${dindex}sdffss${dd}dfssd${iiindex}`}>{dd}</p>
                      })}</> : <>{data[vv.field]}</>}
                    </>}
                  </td>
                })}
                {props.addColHeadings && props.addColHeadings.map((vv, ddIndex) => {
                  return <td key={`${dindex}sdffs${ddIndex}`} data-testid={`pdfExportTableA_R_${dindex}_D_${ddIndex}`}>{Array.isArray(data[vv.field]) === true ? <>{data[vv.field].map((dd: any, iiindex: number) => {
                    return <p key={`${dindex}sdffs${ddIndex}dfssd${iiindex}`}>{dd}</p>
                  })}</> : <>{data[vv.field]}</>}</td>
                })}
              </tr>;
            })}
          </tbody>
        </table>}
      </div>
    </div>
    <div className="btn-toolbar justify-content-between" role="toolbar" aria-label="Toolbar with button groups">
      {sortedCount > 0 ? <p>{sortedCount} of {props.data.length}</p> : <p></p>}
      <div>
        <div className="btn-group">

          {props.enablePrint === true && handlePrint && <button type="button" className="btn btn-info" onClick={(ev: React.MouseEvent) => {
            ev.preventDefault();
            handlePrint();
          }}>
            <FontAwesomeIcon icon={faPrint} />
          </button>
          }

          {props.exportFileName && <>
            {/*style={{ display: 'none' }}  */}
            {/* <div data-testid="export-CSV-debug" style={{ display: 'none' }}>
              <div data-testid="export-CSV-count">{exportCSVColHeadings().length}</div>
              {exportCSVColHeadings().map((thing, thingIndex) => {
                return <div key={thingIndex} >
                  <div className="e2eExportDebug_key" data-testid={`CSVHeading_key_${thingIndex}`}>{thing.key}</div>
                  <div className="e2eExportDebug_label" data-testid={`CSVHeading_label_${thingIndex}`}>{thing.label}</div>
                  <div className="e2eExportDebug_dataSample" data-testid={`CSVHeading_dataSample_${thingIndex}`}>{sortedArrayStore[0] && sortedArrayStore[0][thing.key]}</div>
                </div>;
              })}
            </div> */}




            {/* <CSVLink
              className="btn btn-info e2eCSVDownload"
              data-testid="downloadCSV"
              data={sortedArrayStore}
              filename={`${props.exportFileName}.csv`}
              headers={exportCSVColHeadings()}
              target="_blank"
              asyncOnClick={true}
              onClick={(event: any, done: any) => {
                done();
                // RequestNewLog(addNotification, {
                //   type: ELogType.event,
                //   requestId: '',
                //   sessionId: '',
                //   userId: '',
                //   payload: {},
                //   severity: ESeverity.Note,
                //   thing: props.exportDataContext,
                //   thingId: props.exportDataId,
                //   text: 'CSV Export Used'
                // }).then(ddd => {
                //   done();
                // }).catch(dssd => {
                //   done();
                // })
                // axios.post("/spy/user").then(() => {
                // REQUIRED to invoke the logic of component
                //  });
              }}
            >

              <FontAwesomeIcon icon={faDownload} />
            </CSVLink> */}
            
            </>}

        </div>
      </div>
      <nav aria-label="Page Numbers">

        {pages > 1 && <ul className="pagination justify-content-center">
          {currentPage !== 0 && <li className="page-item">
            <button onClick={(ev) => {
              ev.preventDefault();
              onChoosePageClick(currentPage - 1);
            }} type="button" className={`page-link `}>Previous</button>
          </li>}
          {Array.from(Array(pages).keys()).splice(pgSliceStart, 10).map(v => {
            return <li key={`${v.toString()}page`} className={`page-item ${v === currentPage && 'active'}`}>
              <button disabled={v === currentPage} onClick={(ev) => {
                ev.preventDefault();
                onChoosePageClick(v);
              }} className="page-link">{v + 1}</button></li>
          })}



          {currentPage + 1 !== pages && <li className="page-item">
            <button onClick={(ev) => {
              ev.preventDefault();
              onChoosePageClick(currentPage + 1);
            }} type="button" className={`page-link `}>Next</button>
          </li>}
        </ul>}
        {pages > 1 && <>{currentPage + 1} Of {pages}</>}
      </nav>
      <div>

        {pages > 1 &&

          <Dropdown >
            <Dropdown.Toggle variant="outline-success" id="dropdown-basic">
              Per Page : {[perPage]}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item className="dropdown-item" onClick={(ev) => { ev.preventDefault(); onChangePerPage(5); }}>5</Dropdown.Item>
              <Dropdown.Item className="dropdown-item" onClick={(ev) => { ev.preventDefault(); onChangePerPage(15); }}>15</Dropdown.Item>
              <Dropdown.Item className="dropdown-item" onClick={(ev) => { ev.preventDefault(); onChangePerPage(25); }}>25</Dropdown.Item>
              <Dropdown.Item className="dropdown-item" onClick={(ev) => { ev.preventDefault(); onChangePerPage(50); }}>50</Dropdown.Item>
              <Dropdown.Item className="dropdown-item" onClick={(ev) => { ev.preventDefault(); onChangePerPage(100); }}>100</Dropdown.Item>
              <Dropdown.Item className="dropdown-item" onClick={(ev) => { ev.preventDefault(); onChangePerPage(250); }}>250</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        }


      </div>
    </div>
    {
      props.doWithSelected && selected && selected.length > 0 && <Dropdown >
        <Dropdown.Toggle variant="outline-success" id="dropdown-basic">
          Selected ?
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {props.doWithSelected && props.doWithSelected.map((doWith, index) => {
            return <><Dropdown.Item key={`doWith${index}`} className="dropdown-item" onClick={(ev) => { ev.preventDefault(); doWith.func && doWith.func(selected) }}>{doWith.title}</Dropdown.Item></>
          })}
        </Dropdown.Menu>
      </Dropdown>
    }

  </>);


}

export default DataTable;